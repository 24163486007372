.footer-main {
  position: fixed;
  height: 75px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;

  background-color: #FFF;

  font-family: 'Lato';
  font-size: 16px;
}

.footer-line {
  color: #c8c8c8;
  margin: 0px 20px 5px 20px;
}

.footer-flexcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 20px;
}

.footer-flexcontainer > div > p {
  margin: 0px;
}

.footer-order-number {
  font-size: 20px;
  font-weight: bold;
}

.footer-time-counter > div {
  font-size: 30px;
  font-weight: bold;
}
