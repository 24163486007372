.blend-info-main {
  list-style-type: none;
}

.blend-info-content {
  display: flex;
  flex-direction: row;
}

.blend-info-content h3 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 18px;
}

.blend-info-content h4 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 15px;
}

.blend-info-content p {
  font-size: 15px;
  margin: 0 0 10px 0;
  color: #1e2177;
}

.blend-info-content h4 span {
  font-weight: normal;
  color: #1e2177;
}

.observation-text {
  font-weight: 200;
  word-wrap: break-word;
}
