.options {
  display: flex;
}

.observation-fields {
  margin: 0 0 0 20px;
  width: 90%;
}

.observation-text {
  font-weight: 200;
  word-wrap: break-word;
}
