.barcode-input-main {
  font-family: 'Lato';
  font-size: 12px;
}

.barcode-form {
  display: flex;
  flex-direction: row;
  display: space-around;
}

.barcode-input {
  width: 150px;
  height: 25px;
  margin: 0px 8px 0px 0px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.barcode-button {
  width: 70px;
  height: 30px;
  border-radius: 4px;
  border-width: 0px;
  background-color: rgb(232, 134, 0);
}
