.active-line-item-main {
  font-family: "Lato";
  font-size: 20px;
  margin-top: 10px;
}

.active-line-item-flexcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 20px;
}

.active-line-item-product-detail-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.active-line-item-image {
  width: auto;
  max-height: 160px;
  margin: 2px 5px;
}

.active-line-item-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 530px;
}

.active-line-item-title {
  font-weight: bold;
  margin: 0;
}

.active-line-item-title-mix-name {
  color: #750da5;
}

.active-line-item-title-container {
  display: flex;
  font-size: 24px;
}

.active-line-item-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.active-line-product-details {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0px 20px;
  min-width: 350px;
}

.active-line-product-details>div>p {
  margin: 0;
}

.active-line-item-data-container>p {
  margin: 0;
}

.active-line-item-large-font {
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}

.active-line-item-box {
  border: 1px solid black;
  padding: 5px 10px;
}

.error-icon {
  color: #e50000;
}

.error-icon:hover {
  cursor: pointer;
}

.active-line-item-biper-colors {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 130px;
}

.active-line-item-biper-color {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 200px;
}

.active-line-item-biper-color>p {
  margin: 0;
  min-width: 100px;
}

.active-line-item-red-block {
  background-color: red;
  width: 80px;
  height: 25px;
}

.active-line-item-yellow {
  background-color: #f9e73a;
  width: 80px;
  height: 25px;
}

.active-line-item-cyan {
  background-color: cyan;
  width: 80px;
  height: 25px;
}

.active-line-item-green {
  background-color: green;
  width: 80px;
  height: 25px;
}

.active-error-icon {
  color: #e50000;
}

.active-error-icon:hover {
  cursor: pointer;
}

.active-line-item-issue-container {
  margin: 0px 20px;
  padding: 5px 0px;
}

.active-line-item-issue-container>p {
  margin: 0px 0px 0px 7px;
  padding: 0px 0px 5px 0px;
  font-size: 14px;
}

.active-line-item-observation-div {
  margin-left: 0px;
}

.active-line-item-observation-div p {
  display: inline;
  font-size: 16px;
}

.active-line-item-observation {
  text-align: justify;
  word-break: break-all;
  overflow-wrap: break-word;
  word-wrap: break-word;
  display: inline;
}

.active-line-item-observation-header-text {
  font-weight: bolder;
}

.active-lineitem-reset-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  margin-right: 30px;
}