body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  margin-bottom: 90px;
  margin-top: 65px;
  /*height: 100%;*/
  /*overflow-y: scroll;*/
}

/*[data-reactroot] {
  height: 100% !important;
}

#root {
  height: 100%;
}*/

[data-reactroot] > div:nth-of-type(2){
  height: 100%;
  overflow-y: scroll;
}

/*html {
  height: 100%;
}*/

@media print {
  .print-prep-label {
    visibility: visible;
  }

  .print-prep-iframe {
    visibility: visible;
  }

  .print-prep-completion-label {
    visibility: visible;
  }

  .print-prep-completion-iframe {
    visibility: visible;
  }

  .print-pack-completion-label {
    visibility: visible;
  }

  .print-pack-completion-iframe {
    visibility: visible;
  }
}

@media screen {
  .print-prep-label {
    visibility: hidden;
  }

  .print-prep-iframe {
    visibility: hidden;
  }

  .print-prep-completion-label {
    visibility: hidden;
  }

  .print-prep-completion-iframe {
    visibility: hidden;
  }

  .print-pack-completion-label {
    visibility: hidden;
  }

  .print-pack-completion-iframe {
    visibility: hidden;
  }
}
