.configuration-box-main {
  background-color: #ffffff;
  text-align: center;

  display: flex;
  flex-direction: column;
  max-width: 300px;
}

.configuration-box-main > h3 {
  color: #292b2c;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 1px;

  margin: 10px 0px;
}

.configuration-box-input {
  background-color: #f6f6f6;
  border: 1px solid #ffffff;
  font-size: 14px;
  font-weight: 200;
  text-indent: 15px;
  letter-spacing: 1px;

  height: 55px;
  width: 100%;
  margin-bottom: 10px;
}

.configuration-box-button > button {
  height: 55px !important;
}

.configuration-main {
  width: 100% !important;
}

.configuration-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /*margin: 0px 0px 50px 0px;*/
  width: 100%;
}

.configuration-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.configuration-button-container {
  display: flex;
  flex-direction: column;

  margin: 15px 5px 0px 5px;
}
