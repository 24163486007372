.product-item-main:hover {
  background-color: #c8c8c8;
}

.product-item-main-completed {
  background-color: green;
}

.product-item-main a {
  text-decoration: none;
  color: black;
  display: flex;
  flex-direction: row;
}

.product-item-main-image {
  width: 10%;
  min-width: 150px;
  align-self: center;
}

.product-item-main-image img {
  width: auto;
  max-height: 50px;
  margin: auto 25px;
}

.product-item-main-name {
  font-family: "Lato";
  font-size: 20px;
  font-weight: bold;
  text-align: justify;
  width: 40%;
}

.product-item-main-name > p {
  padding: 0 20px;
}

.product-item-main-number-of-orders {
  font-family: "Lato";
  font-size: 18px;
  text-align: justify;
  width: 20%;
}

.product-item-data-and-info-content {
  font-family: "Lato";
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
}

.product-item-data-and-info-content > p {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: black;
}

.product-item-data-and-info-content > span {
  letter-spacing: 1px;
  color: black;
}

.product-item-starred-symbol {
  width: 10%;
  height: auto;
  color: #d4af37;
  text-align: center;
  margin: auto;
}
