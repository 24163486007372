.button-main {
  color: #ffffff;
  font-weight: 900;
  font-size: 14px;
  text-align: center;

  border-radius: 0px;

  border: 0px solid #fff;
  box-shadow: #fff;

  cursor: pointer;

  height: 25px;
  width: 100%;
  padding: 3px 15px;
}

.button-main:hover {
  cursor: pointer;
}

.button-primary {
  background-color: #2eb411 !important;
}

.button-secondary {
  background-color: #ce3c25;
}

.button-tertiary {
  background-color: #a5a5a5;
  color: #2f2f2f;
}

.button-quaternary {
  background-color: #2f2f2f;
}

.button-icon {
  /*height: 20px;
    width: 19px;*/
  margin: 0px 0px 0px 0px;
  font-size: 20px;
}

.button-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.button-just-text {
  padding: 0px 5px;
}

.button-undo-fulfillment {
  width: 35px;
  padding: 3px 3px;
}
