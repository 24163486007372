.error-page-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 50px 0px 121px 0px;
  color: #EFEFF4;
}

.error-page-main {
  background-color: #464648;
  border: 1px solid #4b4b4b;
  border-radius: 2px;
  width: 460px;
  padding: 15px 20px 23px 20px;
  text-align: center;
}

.error-page-title {
  font-family: 'Lato', sans-serif;
  font-size: 22px;
  line-height: 27px;
  margin: 0px 0px 15px 0px;
}
