.error-modal-main {
  font-family: 'Lato';
  font-size: 16px;
  text-align: center;
}

.error-modal-title {
  font-size: 20px;
  font-weight: 800;
}

.error-modal-form {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.error-modal-input {
  width: 300px;
  height: 100px;
  border-radius: 4px;
  margin: 5px 10px 0px 0px;
}

.error-modal-details-box {
  margin-top: 15px;
}

.error-modal-submit-button {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.error-modal-button {
  width: 65px;
  height: 35px;
  font-weight: bold;
  border-radius: 4px;
  border-width: 0px;
  background-color: rgb(232, 134, 0);
}
