.packaging-modal-main {
  font-family: 'Lato';
  font-size: 16px;
}

.packaging-modal-title {
  font-size: 20px;
  font-weight: 800;
}

.packaging-modal-form {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.preparation-modal-input {
  width: 300px;
  height: 100px;
  border-radius: 4px;
  margin: 5px 10px 0px 0px;
}

.preparation-modal-details-box {
  margin-top: 15px;
}

.preparation-modal-submit-button {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.preparation-modal-button {
  width: 65px;
  height: 35px;
  font-weight: bold;
  border-radius: 4px;
  border-width: 0px;
  background-color: rgb(232, 134, 0);
}

.preparation-item-biper-colors {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 5px 0px;
}

.preparation-item-biper-color > p {
  margin: 5px 0 0 -70px;
  min-width: 150px;
}

.preparation-item-biper-color {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  min-width: 200px;
  height: 30px;
}
