.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*padding: 0px 30px 0px 20px;*/
  background-color: #ededed;

  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
}

.header-main > h3 {
  font-size: 16px;
  /*width: 40%;*/
  margin-left: 15px;
}

.header-main > div {
  display: flex;
  flex-direction: column;
  /*width: 450px;*/
}

.header-main > div > div {
  font-size: 14px;
  font-weight: 200;
}

.header-main > a {
  color: #007eee;
  text-decoration: none;
  width: 120px;
  margin-right: 15px;
  background-color: #ededed;
}

.header-main > a:hover {
  color: #007eee;
  text-decoration: underline;
  background-color: #ededed;
}

.header-version-information {
  font-size: 14px;
  font-weight: 200;
}
