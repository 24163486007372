.line-item-main {
  font-family: "Lato";
  font-size: 16px;
}

.line-item-red {
  background-color: rgba(237, 67, 55, 0.6);
}

.line-item-yellow {
  background-color: rgba(255, 255, 127, 0.6);
}

.line-item-green {
  background-color: rgba(192, 216, 144, 0.6);
}

.line-item-title {
  font-weight: bold;
  margin: 0;
}

.line-item-title-container {
  display: flex;
  font-size: 20px;
}

.line-item-title-mix-name {
  color: #750da5;
}

.line-item-large-font {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.line-item-flexcontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px 20px;
  height: 55px;
}

.line-item-observation-div {
  margin-left: 20px;
  margin-right: 20px;
  padding-right: 20px;
  padding-left: 25px;
}

.line-item-observation-div p {
  display: inline;
}

.line-item-observation {
  text-align: justify;
  word-break: break-all;
  overflow-wrap: break-word;
  word-wrap: break-word;
  display: inline;
}

.line-item-observation-header-text {
  font-weight: bolder;
}

.line-item-data-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 5px;
}

.line-item-data-container > p {
  margin: 0;
}

.line-item-image {
  width: auto;
  max-height: 50px;
  margin: 2px 25px;
}

.line-item-product-detail-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: left;
}

.line-item-weight-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 300px;
  text-align: center;
  margin: 0px 30px 0px 5px;
}

.line-item-line {
  margin: 5px 20px;
  color: #c8c8c8;
}

.error-icon {
  color: #e50000;
}

.error-icon:hover {
  cursor: pointer;
}

.undo-icon:hover {
  cursor: pointer;
}

.line-item-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60px;
}

.line-item-issue-container {
  margin: 0px 20px;
  padding: 5px 0px;
}

.line-item-issue-container > p {
  margin: 0px 0px 0px 25px;
  padding: 0px 0px 5px 0px;
  font-size: 14px;
}

.lineitem-reset-main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
}

.line-item-preparation-image {
  width: auto;
  height: 100%;
  transition: 0.2s;
  margin-right: 20px;
}

.line-item-preparation-image-no-image {
  width: auto;
  height: 100%;
  transition: 0.2s;
  margin-right: 20px;
}

.line-item-preparation-image:hover {
  transform: scale(8);
  transition: 0.6s;
}
.line-item-preparation-image-no-image:hover {
  transform: scale(1);
}
