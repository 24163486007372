.total-price-limit-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
}

.total-price-limit-container > h1 {
  color: red;
}

.total-price-limit-container > span {
  text-transform: uppercase;
}

.total-price-limit-container input {
  width: 70px;
  height: 30px;
  margin-top: 20px;
  border-radius: 4px;
  border-width: 0px;
  align-self: center;
  background-color: rgb(232, 134, 0);
}
