.loading-main {
  font-family: 'Lato';
  font-size: 16px;
  font-weight: 200;

  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 60px 0px 121px 0px;
}

.loading-box {
  border: 1px solid #676767;
  border-radius: 4px;
  width: 300px;
  padding: 10px 20px 20px 20px;

  text-align: center;
}

.loading-text {
  margin-top: 10px;
}
