.packaging-box {
  border: 1px solid #676767;
  border-radius: 4px;
  width: 300px;
  padding: 10px 20px 20px 20px;

  text-align: center;

  margin: 0 auto;
}

.packaging-box-text {
  margin-top: 10px;
}

.packaging-page-order-table {
  width: 100%;
  text-align: left;
  padding: 0px 20px;
}

table.packaging-page-order-table td {
  border-bottom: 1px solid black;
}
