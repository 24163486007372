.line-order-main {
  font-family: "Lato";
  font-size: 16px;
}

.line-order-line {
  margin: 5px 20px;
  color: #c8c8c8;
}

.line-order-red {
  background-color: rgba(237, 67, 55, 0.6);
}

.line-order-yellow {
  background-color: rgba(255, 255, 127, 0.6);
}

.line-order-green {
  background-color: rgba(192, 216, 144, 0.6);
}

.line-order-flexcontainer {
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
  height: auto;
}

.line-order-observation > p {
  display: inline;
  padding-left: 5px;
}

.line-order-data-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.line-order-data-content-title {
  font-weight: bold;
  font-size: 20px;
  padding-left: 5px;
  width: 20%;
}

.line-order-data-content-title i {
  color: #d4af37;
  margin-left: 10px;
}

.line-order-data-content-weight {
  display: flex;
  flex-direction: row;
}

.line-order-data-content-weight > div {
  margin: auto 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.line-order-data-content-weight > div p {
  font-size: 20px;
  font-weight: bold;
  margin: 0 auto;
}

.line-order-issue-container {
  margin-left: 5px;
}

.line-order-issue-container > p {
  margin: 0px 0px;
}
