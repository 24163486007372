.losses-modal {
  top: 30%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  min-width: 20%;
  position: absolute;
  border: 2px solid #f2f2f2;
  background-color: #fff;
}
.losses-modal-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}
.losses-modal-title {
  font-size: 20px;
  font-weight: 800;
}
.losses-modal-input {
  width: 95%;
  padding: 0.25rem;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
}

.losses-modal-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}
.losses-modal-action {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}
.losses-modal-container {
  display: flex;
  width: 100%;
  padding-left: 0.6rem;
}
