.product-header-info-main-content {
  display: flex;
  flex-direction: row;
  margin: 0px 15px;
  padding: 5px;
  border: 1px solid black;
  overflow: hidden !important;
}

.product-header-info-main-content > img {
  max-width: 100%;
  height: auto;
}

.product-header-info-product-content {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.product-header-info-product-content p {
  margin: 2px 0px;
  display: inline-block;
  font-family: "Lato";
  text-align: justify;
  font-size: 20px;
}

.product-header-info-product-content-title > p {
  font-size: 24px;
  font-weight: bold;
}

.product-header-info-product-content-title > i {
  color: #d4af37;
  margin-left: 10px;
}

.product-header-info-product-content-subtitle > span {
  margin-right: 5px;
  font-weight: bold;
  font-family: "Lato";
  text-align: justify;
  font-size: 20px;
}

.product-header-info-product-content-subtitle > p {
  margin-right: 5px;
}

.product-header-info-product-content-info-on-square-main {
  display: flex;
  flex-direction: row;
}

.product-header-info-product-content-info-on-square-main > div {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.product-header-info-product-content-info-on-square-main > div p {
  font-weight: bold;
  font-size: 22px;
}

.product-header-info-product-content-info-on-square-main > div span {
  font-family: "Lato";
  text-align: justify;
  font-size: 20px;
}
